import { Grid, Text } from "@chakra-ui/react";

const SectionTitle = ({title}) => {
    return (
        <Grid templateColumns='repeat(2, 1fr)' >
            <Text fontSize={'1rem'} fontWeight="bold">{title}</Text>
            {/* <Text fontSize={'0.7rem'} textAlign="right" >VIEW ALL</Text> */}
        </Grid>
    )
}

export default SectionTitle;