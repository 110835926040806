import { Box, Center, Flex, Grid, GridItem, HStack, Image, Text, VStack } from "@chakra-ui/react";
import BoxArticle from "../components/BoxArticle";
import BoxText from "../components/BoxText";
import Photos from "../components/Photos";
import SectionTitle from "../components/SectionTitle";
import ShopCard from "../components/ShopCard";
import MetaTags from 'react-meta-tags';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";


const Mylink = ({color}) => {

    const [open, setOpen] = useState(false);
    const images=[
        { src: "/assets/img/imageone.jpeg" },
        { src: "/assets/img/imagetwo.jpeg" },
        { src: "/assets/img/imagethree.jpeg" },
        ];

    return (
    
    <Center 
        // bgImage={"url(https://wp-mktg.prod.getty1.net/istockcontentredesign/wp-content/uploads/sites/5/bfi_thumb/2021_iStock_LatestBGTrends_Hero.jpg-37hacs394rfnlye6q17b1otbsizghpa9fhni65z6lb6gploak.jpeg)"}
        bgSize="cover"
        bgRepeat={"no-repeat"}
        >
        <MetaTags>
            <title>Palette Cafe & Bar - Aalaya</title>
            <meta name="description" content="Palette Cafe & Bar - Aalaya" />
            <link rel="icon" href="/assets/img/aalaya.png" />
            <meta property="og:title" content="Palette Cafe & Bar - Aalaya" />
            <meta property="og:image" content="/assets/img/aalaya.png" />
        </MetaTags>
        <Box
            color={color} 
            padding={'1rem'} 
            maxW="100%">
            <HStack gap={'0.5rem'}>
                <Box>
                    <Image  
                        height={'9rem'}
                        width={'9rem'}
                        objectFit={'cover'}
                        borderRadius={'1rem'}
                        src="/assets/img/palettelogo.jpeg" />
                </Box>
                <Box>
                    <Text fontSize={'1.3rem'} fontWeight="bold" >Palette</Text>
                    <Text fontSize={'1.3rem'} fontWeight="bold" >Cafe & Bar</Text>
                    <Text fontSize={'1rem'} >Sanepa, Lalitpur</Text>
                    {/* <HStack mt={'0.5rem'}> */}
                        {/* <a href="https://www.facebook.com/saiomnika"><Image height={'22px'} src="/assets/img/facebook.png"/></a> */}
                        {/* <a href="https://www.instagram.com/omnikadangol/"><Image height={'22px'} src="/assets/img/instagram.png"/></a> */}
                        {/* <Image height={'22px'} src="/assets/img/linkedin.png"/> */}
                    {/* </HStack> */}
                </Box>
            </HStack>

            <Box 
                borderLeft={'0.5rem solid lightgray'} 
                mt={'1rem'}
                pl="1rem"
                maxW={'40rem'}
                >
                <Text fontSize={'0.8rem'} fontWeight="400">
                Indulge your senses at <b>Palette Cafe and Bar</b>, where art and flavor collide seamlessly. Located at Sanepa, this vibrant establishment invites you to savor a fusion of delectable tastes amidst an artistic ambiance, creating a one-of-a-kind experience for both the palate and the creative spirit.
                </Text>
            </Box>

            <BoxText link="https://www.instagram.com/palettecafe.np/" label="Follow us on Instagram" src="/assets/img/instagram.png"/>
            <BoxText link="https://www.facebook.com/thecafepalette" label="Follow us on Facebook" src="/assets/img/facebook.png"/>
            <BoxText link="https://maps.app.goo.gl/GWNAMmWjiswvpvfh7?g_st=ic" label="Review us on google " src="/assets/img/googlelogo.png"/>

            {/* <BoxArticle /> */}

            <Box
                mt={'1rem'}
            >
                <SectionTitle title="PHOTOS" />

                <HStack 
                    mt="1rem" 
                    gap={'0.5rem'}
                    overflowX="scroll"
                    scrollBehavior={'smooth'}
                    >
                    {
                        images?.map((item,index)=>(
                            <Photos setOpen={setOpen} item={item} index={index}/>
                            ))
                    }

                    <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={images}
                    />
                </HStack>
            </Box>

            {/* <Box
                mt={'1rem'}
            >
                <SectionTitle title="SHOP" />

                <HStack 
                    mt="1rem" 
                    gap={'0.5rem'}
                    overflowX="scroll"
                    scrollBehavior={'smooth'}
                    >
                    <ShopCard />
                    <ShopCard />
                    <ShopCard />
                </HStack>
            </Box>

            <BoxText /> */}

        <Text mt={'1rem'} fontSize={'1rem'} ><a href="https://aalayasofttech.com/" target="_blank">
            <Center>
                <img src="/assets/img/aalayalogo.png" style={{width:'70px'}} alt="aalaya" />
            </Center>
            </a></Text>
        </Box>
    </Center>
    );
}


export default Mylink;